var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"accountbg",style:({
      background: 'url(' + require('@/assets/images/bg.jpg') + ')',
      'background-size': 'cover',
      'background-position': 'center',
    })}),_c('div',{staticClass:"wrapper-page account-page-full"},[_c('div',{staticClass:"card shadow-none"},[_c('div',{staticClass:"card-block"},[_c('div',{staticClass:"account-box"},[_c('div',{staticClass:"card-box shadow-none p-4"},[_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"text-center mt-4"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-dark.png"),"height":"22","alt":"logo"}})])],1),_c('h4',{staticClass:"font-size-18 mt-5 text-center"},[_vm._v("Reset Password")]),_c('form',{staticClass:"mt-4",attrs:{"action":"#"}},[_c('div',{staticClass:"alert alert-success mt-4",attrs:{"role":"alert"}},[_vm._v(" Enter your Email and instructions will be sent to you! ")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"form-group mt-2 mb-0 row"},[_c('div',{staticClass:"col-12 mt-3"},[_c('router-link',{attrs:{"to":"/pages/recoverpwd-2"}},[_c('i',{staticClass:"mdi mdi-lock"}),_vm._v(" Forgot your password? ")])],1)])]),_c('div',{staticClass:"mt-5 pt-4 text-center"},[_c('p',[_vm._v(" Remember It ? "),_c('router-link',{staticClass:"fw-medium text-primary",attrs:{"to":"/pages/login-2"}},[_vm._v("Sign In here")])],1),_c('p',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" Veltrix. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Themesbrand ")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"useremail"}},[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"useremail","placeholder":"Enter email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-12 text-end"},[_c('button',{staticClass:"btn btn-primary w-md",attrs:{"type":"submit"}},[_vm._v(" Reset ")])])])
}]

export { render, staticRenderFns }